import React, { FC } from "react";
import { useReactiveVar } from "@apollo/client";
import { downloadRawScoresReport } from "api/downloadReport";
import { useToast } from "hooks/Toast";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { ReactComponent as Download } from "assets/images/download.svg";
import { useSchools } from "contexts/SchoolsContext";

import { Wrapper, DownloadButton, ButtonWrapper } from "./styles";

type Props = { disabled?: boolean };

const RawScoresSection: FC<Props> = ({ disabled = false }) => {
  const { t } = useTranslation();
  const Toast = useToast();
  const { currentSchoolId } = useSchools();

  const downloadRawScores = (isArchived?: boolean) => {
    downloadRawScoresReport(
      { id: currentSchoolId, isArchived },
      (res) => {
        const filename = `Raw Scores - ${new Date().toISOString()}.csv`;
        fileDownload(res.data, filename);
        Toast("success", t("successAction.reportDownloading"));
      },
      () => {
        Toast("error", t("errorAction.noStudentsCompleted"));
      }
    );
  };

  return (
    <Wrapper>
      {t("teacher.downloadStudentReportsLong")}
      <ButtonWrapper>
        <DownloadButton
          onClick={() => downloadRawScores(false)}
          disabled={disabled}
        >
          <Download /> {t("teacher.activeStudents")}
        </DownloadButton>
        <DownloadButton
          onClick={() => downloadRawScores(true)}
          disabled={disabled}
        >
          <Download /> {t("teacher.archivedStudents")}
        </DownloadButton>
        <DownloadButton onClick={() => downloadRawScores()} disabled={disabled}>
          <Download /> {t("teacher.allStudents")}
        </DownloadButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default RawScoresSection;
