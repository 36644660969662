import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "assets/images/chevron-down-new.svg";
import { ReactComponent as FolderIcon } from "assets/images/folder.svg";
import { OrderDirection, useGetAllFoldersQuery } from "generated/graphql";
import { SearchInput } from "components/molecules/FolderDropdown/styles";
import { useTranslation } from "react-i18next";
import { useSchools } from "contexts/SchoolsContext";
import {
  Caption,
  Dropdown,
  DropdownElement,
  DropdownWrapper,
  FolderItem,
  FolderList,
  SearchInputWrapper,
} from "./styles";

import { Props } from "./types";

const AssignToFolder: FC<Props> = ({ onFolderSelect }) => {
  const { currentSchoolId } = useSchools();
  const ref = useRef<HTMLDivElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { t } = useTranslation();
  const [folderSearch, setFolderSearch] = useState("");

  const { data: allFolders } = useGetAllFoldersQuery({
    variables: {
      where: { school: { id: { equals: currentSchoolId } } },
      orderBy: [{ name: OrderDirection.Asc }],
    },
  });

  const handleClick = (id: string) => {
    onFolderSelect(id);
    setDropdownVisible(false);
  };

  const handleChange = (e: any) => setFolderSearch(e.target.value);

  useEffect(() => {
    function handleClickOutside(
      event: MouseEvent,
      localRef: RefObject<HTMLElement>
    ) {
      if (
        ref &&
        ref.current &&
        localRef === ref &&
        localRef.current &&
        !localRef.current.contains(event.target as HTMLElement)
      ) {
        setDropdownVisible(false);
      }
    }

    document.addEventListener("mouseup", (e) => handleClickOutside(e, ref));
    return () => {
      document.removeEventListener("mouseup", (e) =>
        handleClickOutside(e, ref)
      );
    };
  }, [ref, setDropdownVisible]);

  return (
    <DropdownElement ref={ref}>
      <DropdownWrapper onClick={() => setDropdownVisible(!dropdownVisible)}>
        <FolderIcon />
        <Caption>Assign to folder</Caption>
        <ChevronDownIcon
          style={{
            rotate: dropdownVisible ? "180deg" : "0deg",
            transition: "all 0.2s ease-in",
          }}
        />
      </DropdownWrapper>
      {dropdownVisible && (
        <>
          <Dropdown>
            <SearchInputWrapper>
              <SearchInput
                placeholder={t("global.folderSearch")}
                onChange={handleChange}
                style={{ width: "100%" }}
              />
            </SearchInputWrapper>
            <FolderList>
              {allFolders?.folders
                ?.filter((folder) =>
                  folder.name?.toLowerCase().includes(folderSearch)
                )
                .map((folder) => (
                  <FolderItem
                    key={folder.id}
                    onClick={() => handleClick(folder.id)}
                  >
                    {folder.name}
                  </FolderItem>
                ))}
            </FolderList>
          </Dropdown>
        </>
      )}
    </DropdownElement>
  );
};

export default AssignToFolder;
