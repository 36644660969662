import { ApolloProvider } from "@apollo/client";
import React, { Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import routes from "routes/routes";
import { ThemeProvider } from "styled-components";

import generateRoutes from "utils/generateRoutes";
import client from "utils/apolloClient";
import Toast, { ToastContainer } from "components/molecules/Toast";

import { AppContainer } from "styles/app";
import { theme } from "themes/main";
import { SchoolsProvider } from "contexts/SchoolsContext";

function App() {
  return (
    <Suspense fallback={null}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ToastProvider components={{ ToastContainer, Toast }}>
            <SchoolsProvider>
              <BrowserRouter>
                <AppContainer>
                  <Switch>{generateRoutes(routes)}</Switch>
                </AppContainer>
              </BrowserRouter>
            </SchoolsProvider>
          </ToastProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Suspense>
  );
}

export default App;
