import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetAuthenticatedUserQuery,
  useGetVideosWithViewsQuery,
} from "generated/graphql";

import NavigationBar from "components/molecules/NavigationBar";
import Footer from "components/molecules/Footer";
import paths from "routes/paths";

import { StyledMain, StyledHeader, StyledFooter } from "./styles";
import { VideoModel } from "../../pages/Student/Academy/AcademyDashboard/types";

const Student: FC = ({ children }) => {
  const { t } = useTranslation();

  const [videos, setVideos] = useState<VideoModel[]>([]);

  const { data: userData } = useGetAuthenticatedUserQuery();

  const { data: videoData } = useGetVideosWithViewsQuery({
    variables: { isTutorial: false },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data = videoData?.findVideosWithViews as VideoModel[];
    if (data?.length) {
      setVideos(data);
    }
  }, [videoData]);

  const studentNav =
    userData?.authenticatedItem?.students?.length &&
    userData?.authenticatedItem?.students[0]?.testAttempt?.isCompleted &&
    videos.length
      ? [
          { to: paths.studentDashboard, title: t("student.dashboard") },
          { to: paths.academyDashboard, title: t("academy.academy") },
        ]
      : [];

  const footer = [{ to: "https://careerfit.com/", title: "CareerFit.com" }];

  return (
    <>
      <StyledHeader>
        <NavigationBar navigationItems={studentNav} />
      </StyledHeader>
      <StyledMain>{children}</StyledMain>
      <StyledFooter>
        <Footer footerItems={footer} />
      </StyledFooter>
    </>
  );
};

export default Student;
