import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import Loader from "components/atoms/Loader";

import { authenticatedUserVar, userRoleVar } from "store/authenticatedUser";
import {
  useGetAuthenticatedUserQuery,
  useGetAllSchoolsQuery,
  GetAuthenticatedUserQuery,
} from "generated/graphql";

import paths from "routes/paths";
import { UserRoles } from "types/user";
import { routesByRoles } from "routes/routesByRole";
import { useSchools } from "contexts/SchoolsContext";

const Auth: FC<{ path: string }> = ({ children, path }) => {
  const [isUserAllowed, setUserAllowed] = useState<boolean | null>(null);
  const { loading: authLoading, data: authData } = useGetAuthenticatedUserQuery(
    {
      fetchPolicy: "network-only",
    }
  );
  const { setAllSchools } = useSchools();

  const handleSetRole = (userData?: GetAuthenticatedUserQuery) => {
    if (userData?.authenticatedItem) {
      const isUserAndTeacher =
        userData.authenticatedItem.students?.length &&
        (userData.authenticatedItem.teachers?.filter(
          (teacher) => !teacher.school?.isIndividual
        ).length ||
          userData.authenticatedItem.adminOfSchools?.filter(
            (school) => !school.isIndividual
          ).length);

      const isTeacher =
        (userData.authenticatedItem.teachers?.length &&
          !userData.authenticatedItem.teachers?.some(
            (teacher) => teacher.school?.isIndividual
          )) ||
        userData.authenticatedItem.isAdmin ||
        (userData.authenticatedItem.adminOfSchools?.length &&
          !userData.authenticatedItem.teachers?.some(
            (teacher) => teacher.school?.isIndividual
          ));

      if (isUserAndTeacher) {
        const storedInLS = localStorage.getItem("CF_currentRole") || "";
        const roleToSet =
          storedInLS in UserRoles
            ? (storedInLS as UserRoles)
            : UserRoles.teacher;
        userRoleVar(roleToSet);
      } else if (isTeacher) {
        userRoleVar(UserRoles.teacher);
        localStorage.setItem("CF_currentRole", UserRoles.teacher);
      } else {
        userRoleVar(UserRoles.student);
        localStorage.setItem("CF_currentRole", UserRoles.student);
      }
    }
  };

  const userRole = useReactiveVar(userRoleVar);

  const { data: schoolData } = useGetAllSchoolsQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (authData && authData.authenticatedItem) {
      authenticatedUserVar(authData);
      handleSetRole(authData);
    }
  }, [authData]);

  useEffect(() => {
    if (schoolData) {
      setAllSchools(schoolData);
    }
  }, [schoolData, setAllSchools]);

  useEffect(() => {
    let isRouteAllowed: boolean | null = null;

    if (userRole) {
      isRouteAllowed = routesByRoles[userRole].includes(path);
    }
    setUserAllowed(isRouteAllowed);
  }, [userRole, path]);

  if (authLoading) return <Loader />;

  if (!localStorage.getItem("CF_token") || isUserAllowed === false) {
    return <Redirect to={paths.signIn} />;
  }

  if (!authData?.authenticatedItem) {
    return <Redirect to={paths.signIn} />;
  }

  return <div>{children}</div>;
};
export default Auth;
