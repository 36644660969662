import React, { RefObject, useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "assets/images/chevron-down-new.svg";
import { ReactComponent as FolderIcon } from "assets/images/folder.svg";
import { ReactComponent as GearIcon } from "assets/images/gear.svg";
import { OrderDirection, useGetAllFoldersQuery } from "generated/graphql";

import { useTranslation } from "react-i18next";
import Checkbox from "components/atoms/Checkbox";
import { useHistory, useParams } from "react-router-dom";

import paths from "routes/paths";
import { useSchools } from "contexts/SchoolsContext";
import {
  Label,
  LabelWrapper,
  DropdownContent,
  StyledLink,
  Header,
  Content,
  FolderItem,
  Wrapper,
  SearchInput,
  Pill,
} from "./styles";
import { ClearAll, FolderList } from "../FolderSelector/styles";

const FolderDropdown = () => {
  const { folderIds } = useParams<{ folderIds: string }>();
  const { currentSchoolId } = useSchools();

  const history = useHistory();

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  type FolderArray = {
    [k: string]: boolean;
  };

  const [selectedIds, setSelectedIds] = useState<FolderArray>();

  const toggleDropdown = () => {
    setDropdownVisible((visible) => !visible);
  };

  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const { data: allFolders } = useGetAllFoldersQuery({
    variables: {
      where: { school: { id: { equals: currentSchoolId } } },
      orderBy: [{ name: OrderDirection.Asc }],
    },
  });

  useEffect(() => {
    const explodedFolderIds = folderIds?.split(",") || [];

    const booleanObject = Object.fromEntries(
      explodedFolderIds.map((value) => [value, Boolean(value)])
    );

    setSelectedIds(booleanObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderIds]);

  function toggleIds(folderId: number, updateUrl = true) {
    const nextSelectedIds = { ...selectedIds }; // Create a copy to avoid mutation

    nextSelectedIds[folderId] = !nextSelectedIds[folderId];

    setSelectedIds(nextSelectedIds);

    if (updateUrl) {
      const localSelectedFolders = Object.keys(nextSelectedIds)
        .filter((id) => nextSelectedIds[Number(id)]) // Filter selected IDs
        .join(",");
      history.replace(`/students/${localSelectedFolders}`);
      localStorage.setItem("CF_folderFilter", localSelectedFolders);
    }
  }

  const clearAll = () => {
    setSelectedIds({});
    history.replace(`/students`);
    localStorage.setItem("CF_folderFilter", "");
  };

  const goToFolderManage = () => {
    history.push(paths.folders);
  };

  const [filterFoldersValue, setFilterFoldersValue] = useState("");

  const handleChange = (e: any) => {
    setFilterFoldersValue(e.target.value.toLowerCase());
  };

  useEffect(() => {
    function handleClickOutside(
      event: MouseEvent,
      localRef: RefObject<HTMLElement>
    ) {
      if (
        ref &&
        ref.current &&
        localRef === ref &&
        localRef.current &&
        !localRef.current.contains(event.target as HTMLElement)
      ) {
        setDropdownVisible(false);
      }
    }

    document.addEventListener("mouseup", (e) => handleClickOutside(e, ref));
    return () => {
      document.removeEventListener("mouseup", (e) =>
        handleClickOutside(e, ref)
      );
    };
  }, [ref, setDropdownVisible]);

  return (
    <Wrapper ref={ref}>
      <LabelWrapper onClick={toggleDropdown}>
        <Label>
          <FolderIcon />
          Folder
          {selectedIds && Object.keys(selectedIds).length ? (
            <Pill>{selectedIds && Object.keys(selectedIds).length}</Pill>
          ) : null}
        </Label>
        <ChevronDownIcon
          style={{
            rotate: isDropdownVisible ? "180deg" : "0deg",
            transition: "all 0.2s ease-in",
          }}
        />
      </LabelWrapper>
      <DropdownContent visible={isDropdownVisible}>
        <Header>
          <div>Select the folder</div>
          <StyledLink onClick={goToFolderManage}>
            <GearIcon />
            Manage
          </StyledLink>
        </Header>
        <Content>
          <SearchInput
            placeholder={t("global.folderSearch")}
            onChange={handleChange}
          />
          <FolderList>
            <ClearAll onClick={clearAll}>Clear all</ClearAll>
            {allFolders &&
              allFolders.folders
                ?.filter((folder) =>
                  folder.name?.toLowerCase().includes(filterFoldersValue)
                )
                .map((folder) => (
                  <FolderItem
                    key={folder.id}
                    onClick={() => toggleIds(Number(folder.id))}
                    isActive={(folder?.studentsCount || 0) > 0}
                  >
                    <Checkbox
                      isChecked={
                        selectedIds ? selectedIds[Number(folder.id)] : false
                      }
                      onClick={() => {}}
                    />
                    <div>
                      {folder.name} ({folder.studentsCount})
                    </div>
                  </FolderItem>
                ))}
            <FolderItem onClick={() => toggleIds(-1)} isActive>
              <Checkbox
                isChecked={selectedIds ? selectedIds[-1] : false}
                onClick={() => {}}
              />
              <div>Unassigned</div>
            </FolderItem>
          </FolderList>
        </Content>
      </DropdownContent>
    </Wrapper>
  );
};

export default FolderDropdown;
