import React, { createContext, useContext, useEffect, useState } from "react";
import { GetAllSchoolsQuery } from "generated/graphql";
import { useReactiveVar } from "@apollo/client";
import { authenticatedUserVar } from "store/authenticatedUser";

interface ISchoolsContext {
  currentSchoolId: string;
  setCurrentSchoolId: (schoolId: string) => void;
  allSchools: GetAllSchoolsQuery | undefined;
  setAllSchools: (schools: GetAllSchoolsQuery) => void;
}

const SchoolsContext = createContext<ISchoolsContext>({} as ISchoolsContext);

export const SchoolsProvider = ({ children }: { children: JSX.Element }) => {
  const authenticatedUser = useReactiveVar(authenticatedUserVar);

  const [currentSchoolId, setCurrentSchoolId] = useState<string>("");
  const [allSchools, setAllSchools] = useState<GetAllSchoolsQuery>();

  useEffect(() => {
    if (!authenticatedUser) {
      setCurrentSchoolId("");
      setAllSchools(undefined);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    localStorage.setItem("CF_currentSchool", currentSchoolId);
  }, [currentSchoolId]);

  useEffect(() => {
    const schoolFromLS = localStorage.getItem("CF_currentSchool");
    if (allSchools?.schools?.length) {
      const schoolExists =
        !!schoolFromLS &&
        !!allSchools.schools.find(
          (school) => school.id?.toString() === schoolFromLS
        );

      if (schoolExists) {
        setCurrentSchoolId(schoolFromLS ?? "");
      } else {
        setCurrentSchoolId(allSchools.schools[0].id);
        localStorage.setItem("CF_currentSchool", allSchools.schools[0].id);
      }
    }
  }, [allSchools]);

  return (
    <SchoolsContext.Provider
      value={{
        currentSchoolId,
        setCurrentSchoolId,
        allSchools,
        setAllSchools,
      }}
    >
      {children}
    </SchoolsContext.Provider>
  );
};

export const useSchools = () => useContext(SchoolsContext);
