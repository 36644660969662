import React, { useCallback, useEffect } from "react";

import Button from "components/atoms/Button";
import {
  useEnableIndividualTestMutation,
  useGetAuthenticatedUserQuery,
} from "generated/graphql";
import { useHistory } from "react-router-dom";
import paths from "routes/paths";
import { useToast } from "hooks/Toast";
import { Wrapper, Content, Heading, Details, ButtonWrapper } from "./styles";

const PaymentSuccess = () => {
  const [enableIndividualTest] = useEnableIndividualTestMutation();
  const { data, loading } = useGetAuthenticatedUserQuery();
  const history = useHistory();
  const Toast = useToast();

  const handleNotEnoughBalance = useCallback((err: any) => {
    Toast("error", err.message);
    return setTimeout(() => {
      history.push(paths.selfRegistrationPayment);
    }, 2000);
    // Disable this rule here to not rerender component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function enableTest() {
      return enableIndividualTest({
        variables: {
          schoolId:
            Number(data?.authenticatedItem?.adminOfSchools![0].id) || -1,
          userId: Number(data?.authenticatedItem?.id) || -1,
        },
      });
    }

    if (!loading && data) {
      enableTest().catch((err) => {
        handleNotEnoughBalance(err);
      });
    }
  }, [loading, data, enableIndividualTest, handleNotEnoughBalance]);

  const moveToDashboard = () => history.push("/student");

  return (
    <Wrapper>
      <Content type="success">
        <Heading>Success!</Heading>
        <Details>
          Your payment was successful. You will be now forwarded to the
          assessment dashboard.
        </Details>
        <ButtonWrapper>
          <Button variant="primary" onClick={moveToDashboard}>
            OK
          </Button>
        </ButtonWrapper>
      </Content>
    </Wrapper>
  );
};

export default PaymentSuccess;
