import React, { useState, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ReactComponent as SchoolIcon } from "assets/images/school.svg";
import { ReactComponent as Pencil } from "assets/images/pencil-outline.svg";
import { ReactComponent as Close } from "assets/images/close-big.svg";

import DropdownMenu from "components/molecules/DropdownMenu";
import StudentInfo from "components/molecules/StudentInfo";

import useClickOutside from "hooks/clickOutSide";
import {
  useGetAuthenticatedUserQuery,
  useUnauthenticateUserMutation,
} from "generated/graphql";
import { userRoleVar } from "store/authenticatedUser";
import { useReactiveVar } from "@apollo/client";
import { UserRoles } from "types/user";
import { useSchools } from "contexts/SchoolsContext";

import {
  Container,
  InfoWrapper,
  NameText,
  EmailText,
  CircleContent,
  Circle,
  MobileContainer,
  MobileContainerBackground,
  SidebarHeader,
  SidebarBody,
  School,
  Logout,
  MobileSidebar,
  CloseWrapper,
} from "./styles";
import EditUserModal from "./EditUserModal";
import ChangePasswordModal from "./ChangePasswordModal";

import { Props } from "./types";

const UserInfo: FC<Props> = ({ isMenuOpen, onMenuClose }) => {
  const { t } = useTranslation();

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);

  const userRole = userRoleVar();

  const switchLabelText =
    userRole === "student"
      ? t("user.switchToTeacher")
      : t("user.switchToStudent");

  const history = useHistory();

  const {
    visible: dropdownVisible,
    setVisible: setDropdownVisible,
    ref: containerRef,
  } = useClickOutside();
  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const { currentSchoolId, setCurrentSchoolId } = useSchools();

  const [logout, { client }] = useUnauthenticateUserMutation();

  const { data: authenticatedUser, refetch } = useGetAuthenticatedUserQuery();

  const isStudentAndTeacher = !!(
    authenticatedUser?.authenticatedItem?.students?.length &&
    (authenticatedUser?.authenticatedItem?.teachers?.filter(
      (teacher) => !teacher.school?.isIndividual
    ).length ||
      authenticatedUser?.authenticatedItem?.adminOfSchools?.some(
        (school) => !school.isIndividual
      ))
  );

  const logoutAction = () => {
    logout().then(() => client.resetStore());
    history.push("/login");
  };

  const switchRole = () => {
    if (
      (userRole === UserRoles.student &&
        authenticatedUser?.authenticatedItem?.teachers?.length) ||
      authenticatedUser?.authenticatedItem?.adminOfSchools?.length
    ) {
      localStorage.setItem("CF_currentRole", UserRoles.teacher);

      const firstSchool = authenticatedUser.authenticatedItem.teachers?.[0]
        ?.school?.id
        ? authenticatedUser?.authenticatedItem?.teachers[0].school?.id
        : "";
      setCurrentSchoolId(firstSchool);
    }

    if (
      userRole === UserRoles.teacher &&
      authenticatedUser?.authenticatedItem?.students?.length
    ) {
      localStorage.setItem("CF_currentRole", UserRoles.student);
    }
    history.push("/decider");
  };

  const dropdownElements = [
    {
      onClick: () => setEditModalVisible(true),
      name: t("user.editDetails"),
    },
    {
      onClick: () => setPasswordModalVisible(true),
      name: t("user.changePassword"),
    },
    {
      onClick: () => {
        logoutAction();
      },
      name: t("user.logOut"),
    },
  ];

  if (isStudentAndTeacher) {
    dropdownElements.unshift({
      onClick: () => switchRole(),
      name: switchLabelText,
    });
  }

  const isReportToDownload =
    authenticatedUser?.authenticatedItem?.students &&
    authenticatedUser?.authenticatedItem?.students[0]?.testAttempt
      ?.isCompleted &&
    authenticatedUser?.authenticatedItem?.students[0]?.testAttempt
      ?.isReportPublished &&
    typeof authenticatedUser?.authenticatedItem?.students[0]?.testAttempt
      ?.reportName === "string";

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "auto";
  }, [isMenuOpen]);

  const isTeacher = useReactiveVar(userRoleVar) === UserRoles.teacher;

  return (
    <>
      {isMenuOpen && (
        <MobileSidebar>
          <CloseWrapper>
            <Close onClick={onMenuClose} />
          </CloseWrapper>
          <MobileContainerBackground />
          <MobileContainer>
            <SidebarHeader>
              <div>
                <NameText>
                  {authenticatedUser?.authenticatedItem?.firstName}{" "}
                  {authenticatedUser?.authenticatedItem?.lastName}
                </NameText>
                <EmailText>
                  {authenticatedUser?.authenticatedItem?.email}
                </EmailText>
              </div>
              <div>
                <Pencil onClick={() => setEditModalVisible(true)} />
              </div>
            </SidebarHeader>
            <SidebarBody>
              <StudentInfo
                welcomeText={`${t("user.hi")}, ${
                  authenticatedUser?.authenticatedItem?.firstName
                }!`}
                finished={!!isReportToDownload}
                reportName={
                  authenticatedUser?.authenticatedItem?.students
                    ? (authenticatedUser?.authenticatedItem?.students[0]
                        ?.testAttempt?.reportName as string)
                    : ""
                }
              />
            </SidebarBody>
            <School>
              <SchoolIcon />
              <div>
                {authenticatedUser?.authenticatedItem?.students?.length &&
                  authenticatedUser?.authenticatedItem?.students[0].school
                    ?.name}
              </div>
            </School>
            <Logout onClick={logoutAction}>Log out</Logout>
          </MobileContainer>
        </MobileSidebar>
      )}

      <Container onClick={toggleDropdown} ref={containerRef}>
        <InfoWrapper>
          <NameText>
            {authenticatedUser?.authenticatedItem?.firstName}{" "}
            {authenticatedUser?.authenticatedItem?.lastName}
          </NameText>
          <EmailText>{authenticatedUser?.authenticatedItem?.email}</EmailText>
        </InfoWrapper>
        <Circle>
          {" "}
          <CircleContent>
            {authenticatedUser?.authenticatedItem?.firstName &&
              authenticatedUser?.authenticatedItem?.firstName.substring(0, 1)}
            {authenticatedUser?.authenticatedItem?.lastName &&
              authenticatedUser?.authenticatedItem?.lastName.substring(0, 1)}
          </CircleContent>
        </Circle>
        <DropdownMenu
          elements={dropdownElements}
          offsetTop="5rem"
          position="full"
          visible={dropdownVisible}
        />
      </Container>
      {editModalVisible && (
        <EditUserModal
          isOpen={editModalVisible}
          title={t("user.editData")}
          onClose={() => setEditModalVisible(false)}
          onUserDataChange={refetch}
        />
      )}
      <ChangePasswordModal
        isOpen={passwordModalVisible}
        onClose={() => setPasswordModalVisible(false)}
      />
    </>
  );
};

export default UserInfo;
