import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import Modal from "components/atoms/Modal";
import Input from "components/molecules/Input";
import { FormikProps, useFormik } from "formik";
import { useUpdateFolderMutation } from "generated/graphql";
import { Container, StyledButton } from "./styles";
import { EditFolderProps, AddFolderType } from "./types";

const EditFolderModal: FC<EditFolderProps> = ({
  isOpen,
  onClose,
  title,
  selectedFolder,
}) => {
  const { t } = useTranslation();
  const [updateFolder] = useUpdateFolderMutation();

  const editFolderForm: FormikProps<AddFolderType> = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { folderName: selectedFolder?.name || "" },

    onSubmit: async ({ folderName }, { setErrors }) => {
      updateFolder({
        variables: {
          data: { name: folderName },
          where: { id: selectedFolder?.id },
        },
      })
        .then(() => onClose())
        .catch((e) => setErrors({ folderName: e.message }))
        .finally(() => onClose());
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      size="narrow"
      headerMargin="0 0 4.8rem 0"
    >
      <Container>
        <form
          onSubmit={editFolderForm.handleSubmit}
          method="post"
          autoComplete="off"
        >
          <Input
            name="folderName"
            label={t("global.folderName")}
            placeholder={t("global.folderNamePlaceholder")}
            handleChange={editFolderForm.handleChange}
            handleBlur={editFolderForm.handleBlur}
            value={editFolderForm.values.folderName}
            error={
              editFolderForm.touched.folderName &&
              editFolderForm.errors.folderName
            }
          />
          <StyledButton variant="primary" width="50%" type="submit">
            Save changes
          </StyledButton>
        </form>
      </Container>
    </Modal>
  );
};

export default EditFolderModal;
