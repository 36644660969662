import React, { RefObject, useEffect, useRef, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "assets/images/chevron-down-new.svg";
import { ReactComponent as ReportIcon } from "assets/images/report-icon.svg";

import { useTranslation } from "react-i18next";
import {
  Label,
  LabelWrapper,
  DropdownContent,
  Content,
  FolderItem,
  Wrapper,
} from "./styles";

import { Props } from "./types";

const FolderDropdown = ({ isSelectedNotSent, setIsSelectedNotSent }: Props) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((visible) => !visible);
  };

  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(
      event: MouseEvent,
      localRef: RefObject<HTMLElement>
    ) {
      if (
        ref &&
        ref.current &&
        localRef === ref &&
        localRef.current &&
        !localRef.current.contains(event.target as HTMLElement)
      ) {
        setDropdownVisible(false);
      }
    }

    document.addEventListener("mouseup", (e) => handleClickOutside(e, ref));
    return () => {
      document.removeEventListener("mouseup", (e) =>
        handleClickOutside(e, ref)
      );
    };
  }, [ref, setDropdownVisible]);

  const setReports = (value: "sent" | "unsent" | undefined) => {
    if (setIsSelectedNotSent) {
      if (!value) {
        setIsSelectedNotSent(undefined);
      } else {
        setIsSelectedNotSent((val) => (val === value ? undefined : value));
      }
    }

    setDropdownVisible(false);
  };

  return (
    <Wrapper ref={ref}>
      <LabelWrapper onClick={toggleDropdown}>
        <Label>
          <ReportIcon />
          {isSelectedNotSent
            ? `${
                isSelectedNotSent.charAt(0).toUpperCase() +
                isSelectedNotSent.slice(1)
              } reports`
            : "Reports"}
        </Label>
        <ChevronDownIcon
          style={{
            rotate: isDropdownVisible ? "180deg" : "0deg",
            transition: "all 0.2s ease-in",
          }}
        />
      </LabelWrapper>

      <DropdownContent visible={isDropdownVisible}>
        <Content>
          <FolderItem
            onClick={() => setReports("sent")}
            style={{ fontWeight: isSelectedNotSent === "sent" ? 700 : 300 }}
          >
            Show only sent reports
          </FolderItem>
          <FolderItem
            onClick={() => setReports("unsent")}
            style={{ fontWeight: isSelectedNotSent === "unsent" ? 700 : 300 }}
          >
            Show only unsent reports
          </FolderItem>
          <FolderItem onClick={() => setReports(undefined)}>
            Show all
          </FolderItem>
        </Content>
      </DropdownContent>
    </Wrapper>
  );
};

export default FolderDropdown;
