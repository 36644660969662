import React, { FC, useState } from "react";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";

import { ReactComponent as PaperPlane } from "assets/images/paper-plane.svg";
import { ReactComponent as Pencil } from "assets/images/pencil.svg";
import { ReactComponent as Warning } from "assets/images/toast-warning.svg";
import { ReactComponent as Download } from "assets/images/download.svg";
import { ReactComponent as FolderIcon } from "assets/images/folder.svg";
import { ReactComponent as Unarchive } from "assets/images/unarchive.svg";

import { useToast } from "hooks/Toast";

import Checkbox from "components/atoms/Checkbox";

import Colors from "constants/colors";
import {
  StudentStatusType,
  useResetInterestInventoryMutation,
} from "generated/graphql";

import { downloadStudentReport } from "api/downloadReport";
import { Muted } from "components/atoms/Title";
import {
  Circle,
  TableRow,
  TableCell,
  ActionItem,
  ActionLabel,
  StyledButton,
  Tick,
} from "./styles";
import { Props } from "./types";
import FolderSelector from "../FolderSelector";

const StudentRow: FC<Props> = (props) => {
  const {
    student,
    isSelected,
    onRemove,
    onEdit,
    onToggle,
    onSendMessage,
    onUnarchive,
    isArchived = false,
    studentsRefetch,
  } = props;

  const { t } = useTranslation();
  const Toast = useToast();

  const [folderAssignUser, setFolderAssignUser] = useState<number | null>(null);

  const [localFolder, setLocalFolder] = useState<Record<number, string>>({});

  const [resetInterestInventory] = useResetInterestInventoryMutation();

  const addLocalFolder = (id: number, value: string) => {
    setLocalFolder((prevData) => ({
      ...prevData,
      [id]: value, // Directly set the value for the given ID
    }));
  };

  if (!student) return null;

  const openFolderSelection = (userId: number) => setFolderAssignUser(userId);

  const closeFolderSelection = (
    localUserId: number | null,
    localFolderName?: string
  ) => {
    if (localUserId && localFolderName) {
      addLocalFolder(localUserId, localFolderName);
    }
    if (studentsRefetch) {
      studentsRefetch();
    }
    setFolderAssignUser(null);
  };

  const userStatus = () => {
    if (!student?.user?.isConfirmed)
      return <Circle circleColor={Colors.geyser} />;

    switch (student?.status) {
      case StudentStatusType.NotStarted:
        return <Circle circleColor={Colors.mandy} />;
      case StudentStatusType.InProgress:
        return <Circle circleColor={Colors.tulipTree} />;
      case StudentStatusType.Completed:
        return <Circle circleColor={Colors.oceanGreen} />;
      default:
        return <Circle circleColor={Colors.geyser} />;
    }
  };

  const handleDownloadReport = (id: string, reportName: string) => {
    downloadStudentReport(
      id,
      (res) => {
        fileDownload(res.data, reportName);
        Toast("success", t("successAction.reportDownloading"));
      },
      () => {
        Toast("error", t("errorAction.reportDoesntExist"));
      }
    );
  };

  const onInterestInventoryReset = (resetStudent: any) => {
    const testId = resetStudent.testAttempt?.id || -1;
    resetInterestInventory({
      variables: {
        testId: Number(testId),
      },
    })
      .then(() => {
        Toast("success", "Interest inventory reset successfully");
      })
      .catch(() => {
        Toast("error", "Test is not completed yet");
      });
  };

  const isReport =
    student.status === StudentStatusType.Completed &&
    student.testAttempt?.reportName;

  const isRemovable = student.status === StudentStatusType.NotStarted;
  const isCompleted = student.status === StudentStatusType.Completed;
  const isResetActive = student?.testAttempt?.isInterestInventoryCompleted;
  return (
    <TableRow key={student.user?.id}>
      <TableCell>
        <Checkbox isChecked={isSelected} onClick={() => onToggle(student)} />
      </TableCell>
      <TableCell>
        {student.user?.lastName}, {student.user?.firstName}
      </TableCell>
      <TableCell
        style={{ position: "relative", cursor: "pointer" }}
        onClick={() => openFolderSelection(Number(student.id))}
      >
        {student.folder || localFolder[Number(student.id)] ? (
          <>
            <FolderIcon />{" "}
            {localFolder[Number(student.id)] || student.folder?.name}
          </>
        ) : (
          <Muted>{t("global.folderUnassigned")}</Muted>
        )}
        {Number(student.id) === folderAssignUser && (
          <FolderSelector
            userId={Number(student.id)}
            onClose={closeFolderSelection}
            currentFolderName={
              localFolder[Number(student.id)] || student.folder?.name || ""
            }
          />
        )}
      </TableCell>
      <TableCell centered>{userStatus()}</TableCell>
      <TableCell>
        <ActionItem>
          {!isArchived && onEdit && (
            <ActionItem
              clickable={!student?.user?.isConfirmed}
              disabled={student?.user?.isConfirmed || false}
              data-tip={student?.user?.isConfirmed}
              data-for="onlyNotStarted"
              data-tip-disable={!student?.user?.isConfirmed}
            >
              <>
                <Pencil />
                <StyledButton
                  variant="link"
                  disabled={!!student?.user?.isConfirmed}
                  onClick={() => onEdit(student)}
                >
                  {t("actions.edit")}
                </StyledButton>
              </>
            </ActionItem>
          )}
          {/* <ActionItem
            clickable={!student?.user?.isConfirmed}
            disabled={!isResetActive}
            data-tip-disable={!student?.user?.isConfirmed}
          >
            <>
              <Warning style={{ width: "25px" }} />
              <StyledButton
                variant="link"
                color="warning"
                onClick={() => onInterestInventoryReset(student)}
              >
                Reset Interest Inv.
              </StyledButton>
            </>
          </ActionItem> */}

          {isArchived && onUnarchive && (
            <ActionItem>
              <Unarchive />
              <StyledButton variant="link" onClick={() => onUnarchive(student)}>
                {t("actions.unarchive")}
              </StyledButton>
            </ActionItem>
          )}
          <ActionItem style={{ marginLeft: 0 }}>
            <PaperPlane />
            <StyledButton variant="link" onClick={() => onSendMessage(student)}>
              {t("teacher.message")}
            </StyledButton>
          </ActionItem>
          {isRemovable && (
            <ActionItem danger>
              <StyledButton
                variant="link"
                onClick={() => onRemove(student)}
                style={{ paddingLeft: 0 }}
              >
                {t("actions.remove")}
              </StyledButton>
            </ActionItem>
          )}
        </ActionItem>
      </TableCell>
      <TableCell>
        {isReport && (
          <ActionItem
            data-tip={JSON.stringify({
              verbalScore: `${student.testAttempt?.verbalScore}/40`,
              numericalScore: `${student.testAttempt?.numericalScore}/40`,
              abstractScore: `${student.testAttempt?.abstractScore}/40`,
            })}
            data-for="results"
          >
            <Download />
            {student.testAttempt?.isReportSent && <Tick />}
            <StyledButton
              variant="link"
              onClick={() =>
                handleDownloadReport(
                  student.id || "",
                  student.testAttempt?.reportName || ""
                )
              }
              style={{ paddingLeft: "0.8rem" }}
            >
              <ActionLabel style={{ marginLeft: 0 }}>
                {t("teacher.pdf")}
              </ActionLabel>
            </StyledButton>
          </ActionItem>
        )}
      </TableCell>
    </TableRow>
  );
};

export default StudentRow;
