import styled from "styled-components";

import { ReactComponent as ExclamationIcon } from "assets/images/exclamation-mark.svg";
import { ReactComponent as SuccessIconSvg } from "assets/images/check-circle.svg";
import { ReactComponent as WarningIconSvg } from "assets/images/check-circle-yellow.svg";
import { ReactComponent as ErrorIconSvg } from "assets/images/check-circle-error.svg";
import RadioOffSvg from "assets/images/radio-off.svg";
import RadioOnSvg from "assets/images/radio.svg";
import Magnifier from "assets/images/magnifier.svg";

import Button from "components/atoms/Button";
import { boolean } from "yup";

export const Container = styled.div`
  font-family: "Roboto";
  font-size: 1.6rem;
`;

export const SectionHeader = styled.h2`
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4rem;
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.colors.mineShaft};
`;

export const SectionContent = styled.div`
  padding: 2.4rem 0 3.2rem 0;
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.mystic}`};
  margin-bottom: 3.2rem;
`;

export const InvitationSection = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
  font-size: 1.6rem;
`;

export const ChipsWrapper = styled.div`
  margin-left: 1.6rem;
  width: 100%;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.mystic};
`;

export const TemplateSection = styled.div`
  font-size: 1.6rem;
  display: flex;
  padding: 2rem 0;
  align-items: center;
  justify-content: space-between;
`;

export const TemplateLabel = styled.div`
  margin-right: 1.6rem;
`;

export const EmailTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.aquaHaze};
  border: 1.5px solid ${({ theme }) => theme.colors.mishka};
  border-radius: 4px;
  margin-bottom: 0.8rem;
  height: 7.2rem;
  padding-left: 4rem;
`;

export const EmailTextWrapper = styled.div`
  height: 33rem;
  margin-bottom: 4rem;
  position: relative;
`;

export const AttachSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AttachSectionLabel = styled.div`
  margin-left: 0.8rem;
`;

export const ButtonsSection = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  gap: 1.6rem;
  margin-top: 4rem;
  font-size: 1.6rem;

  ${({ fullWidth }) =>
    fullWidth && "width: 100%; justify-content: space-between;"}
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

export const EmailTitle = styled.input`
  width: 100%;
  height: 100%;
  cursor: text;
`;

export const TitleLabel = styled.label`
  text-transform: capitalize;
  font-size: 1.6rem;
  font-weight: 500;
  margin-right: 0.8rem;
`;

export const ErrorMessage = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.01rem;
  color: ${({ theme }) => theme.colors.newYorkPink};
`;

export const SelectOptions = styled.div<{ top?: number; left?: number }>`
  position: absolute;
  width: 16rem;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 2px 6px 2px rgba(60, 64, 67, 0.149);
  border-radius: 4px;
  z-index: 1;
`;

export const VariablesSelectWrapper = styled.div`
  position: relative;
`;

export const TemplateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Option = styled.div`
  text-align: start;
  padding: 1rem 2rem;
  font-family: "Roboto";
  font-size: 1.3rem;
  text-align: center;

  &:hover {
    background: ${({ theme }) => theme.colors.nepal};
    cursor: pointer;
  }
`;

export const DropContent = styled.div`
  width: 100%;
  min-height: 26rem;
  background: ${({ theme }) => theme.colors.linkWater};
  border: ${({ theme }) => `1px dashed ${theme.colors.denim}`};
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 4rem 0;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const StudentPreviewList = styled.ul`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: ${({ theme }) => `1px solid ${theme.colors.mystic}`};
  height: 460px;
  overflow: auto;
`;

export const FlexHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const StudentPreviewItem = styled.li`
  display: flex;
  padding: 1.8rem 3.2rem;
  font-size: 1.4rem;

  &:nth-child(odd) {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      #d8ddf2;
  }
  &:nth-child(even) {
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const DNDContainer = styled.div`
  width: 38.6rem;
  display: flex;
  flex-direction: column;
`;

export const StudentsUploadButtons = styled.div`
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
`;

export const CSVUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DNDText = styled.div`
  display: flex;
`;

export const BoldText = styled.div`
  font-weight: bold;
  margin-right: 0.6rem;
`;

export const StudentName = styled.div`
  width: 40%;
`;

export const PreviewWrapper = styled.div`
  width: 100%;
`;

export const PreviewHeader = styled.div`
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 3.2rem;
`;

export const AttachStudentsSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.8rem;
  margin-top: 1rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.stormGray};
`;

export const StyledExclamationIcon = styled(ExclamationIcon)`
  margin-right: 0.8rem;
`;

export const UploadStudentsButton = styled(Button)`
  margin-bottom: 0.8rem;
`;

export const ErrorContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.amour};
  color: ${({ theme }) => theme.colors.mulberry};
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 2rem;
`;

export const ErrorsList = styled.ul`
  list-style: disc;
  margin: 0.8rem 0;

  li {
    list-style: disc;
    margin-left: 2rem;
    font-weight: 500;
  }
`;

export const DownloadCSVButton = styled(Button)`
  margin: 2rem auto 0;
  align-items: center;
  display: flex;
  min-width: initial;
  width: initial;

  &:hover {
    text-decoration: none;
  }
`;

export const TextSection = styled.div`
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem;
  padding: 1rem;
  gap: 2rem;
`;

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  width: 100%;
`;

export const ResultElement = styled.div`
  display: flex;
  padding: 1rem;
  font-size: 2rem;
  font-weight: bold;
`;

export const ExpandedResultDetails = styled.ul`
  list-style-type: circle;
`;

export const ExpandedResultDetailsItem = styled.li`
  color: ${({ theme }) => theme.colors.denim};
  list-style: circle;
  padding: 0.5rem;
`;

export const SuccessIcon = styled(SuccessIconSvg)`
  height: 3rem;
  width: auto;
  padding-right: 4rem;
`;

export const WarningIcon = styled(WarningIconSvg)`
  height: 3rem;
  width: auto;
  padding-right: 4rem;
`;

export const ErrorIcon = styled(ErrorIconSvg)`
  height: 3rem;
  width: auto;
  padding-right: 4rem;
`;

export const ResultText = styled.div`
  width: 25rem;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 1170px;
  margin: auto;
`;

export const PanelWrapper = styled.div`
  display: flex;
  gap: 2.4rem;
  align-items: flex-start;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 3.2rem;
  margin-left: 33rem;
`;

export const Panel = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 3.2rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 1.6rem;
`;

export const FolderRow = styled.div<{ selected?: boolean }>`
  padding: 1.6rem;
  font-size: 1.4rem;
  font-style: normal;
  height: 4.8rem;
  font-weight: 400;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.geyser};
    ${({ selected, theme }) =>
      selected &&
      `
    border-bottom: 1px solid ${theme.colors.denim};
   
  `}
  }

  ${({ selected, theme }) =>
    selected &&
    `
    border: 1px solid ${theme.colors.denim};
    border-radius: 8px;
    background:  #E8F4FA;
  `}
`;

export const FolderWrapper = styled.div`
  overflow: auto;
  height: 250px;
`;

export const SearchInput = styled.input`
  height: 40px;
  width: 326px;

  background-color: #f7f8fc;
  border: 1px solid ${({ theme }) => theme.colors.mystic};
  background-image: url(${Magnifier});
  background-repeat: no-repeat;
  background-position: 5px 10px;
  padding-left: 2.5rem;
  border-radius: 2rem;
`;

export const StyledRadio = styled.input`
  appearance: none;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: -1px;
  background-image: url(${RadioOffSvg});
  &:checked {
    background-image: url(${RadioOnSvg});
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
