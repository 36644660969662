import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import Modal from "components/atoms/Modal";
import Input from "components/molecules/Input";
import { FormikProps, useFormik } from "formik";
import { useCreateFolderInSchoolMutation } from "generated/graphql";
import { useSchools } from "contexts/SchoolsContext";
import { Container, StyledButton } from "./styles";
import { AddFolderProps, AddFolderType } from "./types";

const AddFolderModal: FC<AddFolderProps> = ({
  isOpen,
  onClose,
  title,
  userId,
}) => {
  const { t } = useTranslation();
  const { currentSchoolId } = useSchools();
  const [createFolder] = useCreateFolderInSchoolMutation();

  const addFolderForm: FormikProps<AddFolderType> = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { folderName: "" },

    onSubmit: async ({ folderName }, { setErrors, resetForm }) => {
      let variables;
      if (userId) {
        variables = {
          name: folderName,
          schoolId: Number(currentSchoolId),
          studentIds: [userId],
        };
      } else {
        variables = {
          name: folderName,
          schoolId: Number(currentSchoolId),
        };
      }

      createFolder({
        variables,
      })
        .then(async () => {
          resetForm();
          onClose();
        })
        .catch((e) => setErrors({ folderName: e.message }));
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      size="narrow"
      headerMargin="0 0 4.8rem 0"
    >
      <Container>
        <form
          onSubmit={addFolderForm.handleSubmit}
          method="post"
          autoComplete="off"
        >
          <Input
            name="folderName"
            label={t("global.folderName")}
            placeholder={t("global.folderNamePlaceholder")}
            handleChange={addFolderForm.handleChange}
            handleBlur={addFolderForm.handleBlur}
            value={addFolderForm.values.folderName}
            error={
              addFolderForm.touched.folderName &&
              addFolderForm.errors.folderName
            }
          />
          <StyledButton variant="primary" width="50%" type="submit">
            {t("global.create")} {userId && "and assign"}
          </StyledButton>
        </form>
      </Container>
    </Modal>
  );
};

export default AddFolderModal;
