import React, { FC, useState } from "react";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Modal from "components/atoms/Modal";
import RadioButton from "components/atoms/RadioButton";
import Label from "components/atoms/Label";
import Input from "components/molecules/Input";
import Button from "components/atoms/Button";
import { firstName, lastName, email } from "helpers/formValidation";
import { useToast } from "hooks/Toast";

import {
  useInviteTeacherMutation,
  useInviteSchoolAdminMutation,
} from "generated/graphql";
import { useSchools } from "contexts/SchoolsContext";
import { Props } from "./types";
import {
  RadioButtonsWrapper,
  RadioButtonWrapper,
  ButtonsSection,
  ModalContentWrapper,
} from "./styles";

const addTeacherInitialValues = {
  email: "",
  firstName: "",
  lastName: "",
  saveAndAdd: null,
};

const AddTeacherModal: FC<Props> = ({ isOpen, onClose, title }) => {
  const { t } = useTranslation();
  const Toast = useToast();
  const [
    inviteTeacher,
    { loading: invitingTeacher },
  ] = useInviteTeacherMutation();
  const [
    inviteSchoolAdmin,
    { loading: invitingSchoolAdmin },
  ] = useInviteSchoolAdminMutation();

  const roles = [
    { id: "1", name: t("global.teacher") },
    { id: "2", name: t("global.schoolAdmin") },
  ];

  const [selectedRole, setSelectedRole] = useState(roles[0].id);
  const { currentSchoolId } = useSchools();

  const isSending = invitingTeacher || invitingSchoolAdmin;

  const prepareRequestData = (data: typeof addTeacherInitialValues) => {
    return {
      email: data.email.toLowerCase(),
      firstName: data.firstName,
      lastName: data.lastName,
      schoolId: Number(currentSchoolId),
    };
  };

  const handleAddTeacher = (
    values: {
      email: string;
      firstName: string;
      lastName: string;
      saveAndAdd: null;
    },
    onSuccess: VoidFunction,
    onFinal: VoidFunction
  ) => {
    if (selectedRole === "1") {
      inviteTeacher({
        variables: prepareRequestData(values),
      })
        .then((res) => {
          if (
            res.data?.inviteTeacher?.existingTeachers &&
            res.data?.inviteTeacher?.existingTeachers.length > 0
          ) {
            Toast("warning", t("warningAction.teacherExists"));
          } else {
            Toast("success", t("successAction.teacherAdded"));
            onSuccess();
          }
        })
        .catch((e) => {
          Toast("error", e.message || t("errorAction.generalError"));
        })
        .finally(() => {
          onFinal();
        });
    } else {
      inviteSchoolAdmin({
        variables: prepareRequestData(values),
      })
        .then(() => {
          Toast("success", t("successAction.schoolAdminAdded"));
          onSuccess();
        })
        .catch((e) => {
          Toast("error", e.message || t("errorAction.generalError"));
        })
        .finally(() => {
          onFinal();
        });
    }
  };

  const addTeacherForm: FormikProps<typeof addTeacherInitialValues> = useFormik(
    {
      initialValues: addTeacherInitialValues,
      validationSchema: Yup.object({
        firstName,
        lastName,
        email,
      }),

      // TODO: Add InviteSchoolAdmin when ready on BE
      onSubmit: async (values, { resetForm }) => {
        if (values.saveAndAdd) {
          handleAddTeacher(
            values,
            () => {},
            () => {
              resetForm();
              setSelectedRole(roles[0].id);
            }
          );
        } else {
          handleAddTeacher(
            values,
            () => {
              onClose();
            },
            () => {
              resetForm();
              setSelectedRole(roles[0].id);
            }
          );
        }
      },
    }
  );

  const handleRoleChange = (id: string) => {
    setSelectedRole(id);
  };

  const closeAndReset = () => {
    onClose();
    addTeacherForm.resetForm();
    setSelectedRole(roles[0].id);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeAndReset} title={title} size="narrow">
      <ModalContentWrapper>
        <form onSubmit={addTeacherForm.handleSubmit} autoComplete="off">
          <Label>Role</Label>
          <RadioButtonsWrapper>
            {roles.map((role) => {
              return (
                <RadioButtonWrapper key={role.id}>
                  <RadioButton
                    isSelected={selectedRole === role.id}
                    name={role.name}
                    value={role.id}
                    onClick={handleRoleChange}
                  />
                </RadioButtonWrapper>
              );
            })}
          </RadioButtonsWrapper>
          <Input
            name="email"
            label={t("global.emailAddressLabel")}
            placeholder={t("global.emailAddressPlaceholder")}
            handleChange={addTeacherForm.handleChange}
            handleBlur={addTeacherForm.handleBlur}
            value={addTeacherForm.values.email}
            error={addTeacherForm.touched.email && addTeacherForm.errors.email}
          />
          <Input
            name="firstName"
            label={t("global.firstNameLabel")}
            placeholder={t("global.firstNamePlaceholder")}
            handleChange={addTeacherForm.handleChange}
            handleBlur={addTeacherForm.handleBlur}
            value={addTeacherForm.values.firstName}
            error={
              addTeacherForm.touched.firstName &&
              addTeacherForm.errors.firstName
            }
          />
          <Input
            name="lastName"
            label={t("global.lastNameLabel")}
            placeholder={t("global.lastNamePlaceholder")}
            handleChange={addTeacherForm.handleChange}
            handleBlur={addTeacherForm.handleBlur}
            value={addTeacherForm.values.lastName}
            error={
              addTeacherForm.touched.lastName && addTeacherForm.errors.lastName
            }
          />
          <ButtonsSection>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                addTeacherForm.handleSubmit();
                addTeacherForm.setFieldValue("saveAndAdd", false, false);
              }}
              style={{ width: "auto" }}
              disabled={isSending}
            >
              {t("actions.save")}
            </Button>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                addTeacherForm.handleSubmit();
                addTeacherForm.setFieldValue("saveAndAdd", true, false);
              }}
              style={{ width: "auto" }}
              disabled={isSending}
            >
              {t("teacher.saveAndAdd")}
            </Button>
          </ButtonsSection>
        </form>
      </ModalContentWrapper>
    </Modal>
  );
};

export default AddTeacherModal;
