import React, { FC } from "react";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";

import DropdownMenu from "components/molecules/DropdownMenu";
import Loader from "components/atoms/Loader";

import { authenticatedUserVar } from "store/authenticatedUser";
import { useSchools } from "contexts/SchoolsContext";
import { Title, CaretDown, DropdownWrapper } from "./styles";
import { Props } from "./types";

const SchoolDropdown: FC<Props> = ({
  visible,
  onSelect,
  offsetTop,
  context,
  searchingFor,
}) => {
  const { allSchools, currentSchoolId } = useSchools();
  const { t } = useTranslation();
  const authenticatedUser = useReactiveVar(authenticatedUserVar);

  const availableSchools =
    allSchools && allSchools.schools
      ? allSchools.schools.filter((school) => {
          if (authenticatedUser.authenticatedItem?.isAdmin) {
            return true;
          }
          if (context === "student") {
            return school?.students?.some(
              (student) =>
                student?.user?.id === authenticatedUser.authenticatedItem?.id
            );
          }
          if (context === "teacher") {
            return (
              school?.teachers?.some(
                (teacher) =>
                  teacher?.user?.id === authenticatedUser.authenticatedItem?.id
              ) ||
              school?.schoolAdmins?.some(
                (schoolAdmin) =>
                  schoolAdmin?.id === authenticatedUser.authenticatedItem?.id
              )
            );
          }
          return false;
        })
      : [];

  const elements = availableSchools.map((school) => {
    let searchProps: string[] = [];

    if (searchingFor === "students") {
      searchProps =
        school?.students?.map((student) => student.user?.email as string) || [];
    }

    if (searchingFor === "teachers") {
      searchProps = [
        school?.teachers?.map((teacher) => teacher.user?.email),
        school?.schoolAdmins?.map((schoolAdmin) => schoolAdmin?.email),
      ].flatMap((email) => (email as unknown) as string);
    }

    return {
      name: `${school?.name}###${school?.code}`,
      content: {
        type: "string",
        props: searchProps,
        key: school?.id || "1",
      },
      onClick: () => onSelect(school?.id || ""),
    };
  });

  if (availableSchools.length) {
    return (
      <>
        <Title>
          {availableSchools.find((school) => school?.id === currentSchoolId)
            ?.name ||
            availableSchools[0]?.name ||
            t("user.selectSchool")}
          <CaretDown />
        </Title>

        <DropdownWrapper>
          <DropdownMenu
            position="full"
            elements={elements}
            offsetTop={offsetTop || "6rem"}
            style={{ borderTop: "1px solid #ccc" }}
            visible={visible}
            filterable={elements.length > 5}
          />
        </DropdownWrapper>
      </>
    );
  }
  return <Loader />;
};

export default SchoolDropdown;
