import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  responseType: "blob",
  headers: {},
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("CF_token");

  if (token && !config?.headers?.Authorization) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...(config.headers || {}),
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

export default axiosInstance;
